import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']
  static values = {
    preventSubmit: Boolean
  }

  clearSiblingFiltersAndSubmit(event) {
    this.deselectAllCheckboxesInTheSameRow(event)

    if (this.preventSubmitValue) return

    this.element.requestSubmit()
  }

  deselectAllCheckboxesInTheSameRow(event) {
    const selectedCheckboxFilterKey = event.target.dataset.filterKey
    const selectedCheckboxValue = event.target.value

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.filterKey === selectedCheckboxFilterKey && checkbox.value !== selectedCheckboxValue) {
        checkbox.checked = false
      }
    })
  }
}
